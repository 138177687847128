/* Landing configurations - global scope */
/* 
 * Define a landing pages properties
 */
const helper = {};

const portal_name = "Playtheque"; // global name

const product_name = {
    'LV': 'Playtheque',
    'LT': 'Playtheque',
    'SK': 'Playtheque',
    'FI': 'Playtheque',
    'CZ': 'Playtheque',
    'RS': 'Playtheque',
    'SRB': 'Playtheque'
};

const product_dns = {
    'Playtheque': 'playtheque.com',
    'Carefizz': 'carefizz.com',
    'Appiland': 'appiland.com'
}

helper.gtm_array = {
    "lvgo": "GTM-T92Z4QN",         // lvgo playtheque
    "ltgo": "GTM-T92Z4QN",         // ltgo playtheque
    "skgo": "GTM-T92Z4QN",         // skgo playtheque
    "figo": "GTM-T92Z4QN",         // figo playtheque
    "cz": "GTM-T92Z4QN",         // cz playtheque
    "rs": "GTM-T92Z4QN",         // rs playtheque
    "srb": "GTM-T92Z4QN",         // srb playtheque
    "default": "GTM-5XFHLVS"       // Affiliates landing
}

const language_phones_format = {
    'LV': '2xxxxxxx',
    'LT': '6xxxxxxx',
    'SK': '09xxxxxxxx',
    'FI': '04xxxxxxxx',
    'CZ': '7xxxxxxxx',
    'RS': '06xxxxxxxx',
    'SRB': '06xxxxxxxx'
};

const msisdn_phones_format = {
    'UK': '44xxxxxxxxx',
    'LT': '3706xxxxxxx',
    'LTU': '3706xxxxxxx',
    'LV': '3712xxxxxxx',
    'LVA': '3712xxxxxxx',
    'EE': '3725xxxxxxx',
    'FI': '3584xxxxxxxx',
    'SK': '4219xxxxxxxx',
    'RO': '407xxxxxxxx',
    'ROU': '407xxxxxxxx',
    'SE': '467xxxxxxxx',
    'RS': '3816xxxxxxxx',
    'SRB': '3816xxxxxxxx',
    'CZ': '4207xxxxxxxx'
};

// apis url
helper.api_PhoneNumber = 'https://api.hubdelivery.io/landing/providePhoneNumberV2';
helper.api_PhoneNumber_v3= 'https://api.hubdelivery.io/landing/providePhoneNumberV3';
helper.api_PhoneNumber_v4 = 'https://api.hubdelivery.io/landing/providePhoneNumberV4'; // test goal
helper.api_ValidatePin = 'https://api.hubdelivery.io/landing/validatePinCodeV2';
helper.api_SendAccessCodeByEmail = 'https://api.hubdelivery.io/landing/sendAccessCodeByEmail';
helper.api_checkNewSubscription = 'https://api.hubdelivery.io/landing/checkNewSubscription';
helper.api_checkNewSubscription_v2 = 'https://api.hubdelivery.io/landing/checkNewSubscriptionV2';
helper.api_checkIfSubscribed = 'https://api.hubdelivery.io/landing/checkIfSubscribed';

helper.api_LegalTerms = 'https://api.hubdelivery.io/store/getPortalTermsOfService';
helper.api_LegalPrivacy = 'https://api.hubdelivery.io/store/getPortalPrivacyPolicy';
helper.api_LegalCookiesPolicy = 'https://api.hubdelivery.io/store/getPortalCookiesPolicy';
helper.api_LegalContact = 'https://api.hubdelivery.io/store/getPortalContact';

// tracking api url
helper.trackingAPI = 'https://keepan.eyeonev.com/ent';


// setup urls of landigs 
helper.landing_urls = {
    "dynamic_pin": "/dyn_pin/",
    "dynamic_sms": "/dyn_sms/",
    "download_pin": "/dwld_pin/",
    "download_sms": "/dwld_sms/",
    "game_pin": "/gm_pin/",
    "game_sms": "/gm_sms/",
    "generic_pin": "/gnrc_pin/",
    "generic_sms": "/gnrc_sms/",
    "secure_pin": "/scr_pin/",
    "secure_sms": "/scr_sms/",
    "player_pin": "/plyr_pin/",
    "player_sms": "/plyr_sms/",
    "stream_pin": "/strm_pin/",
    "stream_sms": "/strm_sms/",
    "videotube_pin": "/vtube_pin/",
    "videotube_sms": "/vtube_sms/",
    // mixed flows
    "dynamic_mix": "/dyn_mix/",
    "download_mix": "/dwld_mix/",
    "game_mix": "/gm_mix/",
    "generic_mix": "/gnrc_mix/",
    "secure_mix": "/scr_mix/",
    "player_mix": "/plyr_mix/",
    "stream_mix": "/strm_mix/",
    "videotube_mix": "/vtube_mix/",

    "dynamic_mix_pin": "/dyn_mix/pin/",
    "dynamic_mix_sms": "/dyn_mix/sms/",
    "download_mix_pin": "/dwld_mix/pin/",
    "download_mix_sms": "/dwld_mix/sms/",
    "game_mix_pin": "/gm_mix/pin/",
    "game_mix_sms": "/gm_mix/sms/",
    "generic_mix_pin": "/gnrc_mix/pin/",
    "generic_mix_sms": "/gnrc_mix/sms/",
    "secure_mix_pin": "/scr_mix/pin/",
    "secure_mix_sms": "/scr_mix/sms/",
    "player_mix_pin": "/plyr_mix/pin/",
    "player_mix_sms": "/plyr_mix/sms/",
    "stream_mix_pin": "/strm_mix/pin/",
    "stream_mix_sms": "/strm_mix/sms/",
    "videotube_mix_pin": "/vtube_mix/pin/",
    "videotube_mix_sms": "/vtube_mix/sms/",
};

// click/mo sms text
helper.sms_info = {
    'LV_kw': 'PT',
    'LV_sc': '1897',
    'LT_kw': 'PLT',
    'LT_sc': '1394',
    'SK_kw': 'KOD #SUBCODE#',     // GET for CF, KOD for PT
    'SK_sc': '8844',
    'FI_kw': 'KOODI OK',
    'FI_sc': '17271',
    'CZ_kw': 'ANO PT',
    'CZ_sc': '90011',
    'RS_kw': 'COD',
    'RS_sc': '1234',
    'SRB_kw': 'COD',
    'SRB_sc': '1234',
}

// tracking default to landing pages
helper.tracking_aff = "AFFg0oG1eAB";
helper.tracking_src = "800PROVSUB814";


helper.affiliates_array = {
    "AFFED8bcEzc": "DF",
    "AFFg0oG1eAB": "GL",
    "AFFPGhqxeM9": "MS",
    "AFFoRg73AiL": "AM"
};

helper.landing_code = {
   "Playtheque": "PT",
   "Carefizz": "CF",
   "Appiland": "AP"
};
    
helper.tracking_google_lpc = {
    "LV": {
        "download_pin": "LPClvggle01",
        "generic_pin": "LPClvggle02"
    },
    "LT": {
        "generic_pin": "LPCAaQQSs01"
    },
    "SK": {
        "player_sms": "LPCskg0g101",
        "stream_sms": "LPCskg0g102",
        "videotube_sms": "LPCskg0g103",
        "generic_sms": "LPCskg0g199"
    },
    "RS": {
        "generic_pin": "LPCaaZZRk01"
    },
    "SRB": {
        "dynamic_pin": "LPCaaZZRk02",
        "generic_pin": "LPCaaZZRk02"
    },
    "FI": {
        "download_mix": "LPCfiooLe01",
        "generic_mix": "LPCfiooLe02",
        "player_mix": "LPCgGgGoO01",
        "stream_mix": "LPCgGgGoO05",
        "videotube_mix": "LPCgGgGoO09"
    },
    "CZ": {
        "dynamic_mix": "LPCoooGgZ41",
        "player_mix": "LPCoooGgZ01",
        "stream_mix": "LPCoooGgZ02",
        "videotube_mix": "LPCoooGgZ03",
        "generic_mix": "LPCoooGgZ22"
    }
}

helper.tracking_default_lpc = {
    "LV": {
        "pin": "LPC1sr7w1Dt",
        "sms": "LPC1IU6NQXn",
        "mix": "LPCB77oR01j"
    },
    "SK": {
        "pin": "LPCxUpKO9TG",
        "sms": "LPC8rpphIzu",
        "mix": "LPCRiTWncXa",
    },
    "FI": {
        "pin": "LPCAoeere34",
        "sms": "LPCApdf8821",
        "mix": "LPCetoui123"
    },
    "CZ": {
        "pin": "LPCQdf87801",
        "sms": "LPCQdf87802",
        "mix": "LPCQdf87803"
    },
    "RS": {
        "pin": "LPCqqbbSSd1",
        "sms": "LPCqqbbSSd2",
        "mix": "LPCqqbbSSd3"
    },
    "SRB": {
        "pin": "LPCqqbbSSd4",
        "sms": "LPCqqbbSSd5",
        "mix": "LPCqqbbSSd6"
    }
}

const landing_name_array = {
    "dynamic_pin": "Dynamic_PIN",
    "dynamic_sms": "Dynamic_SMS",
    "dynamic_mix": "Dynamic_MIX",
    "download_pin": "Download_PIN",
    "download_mix": "Download_MIX",
    "generic_pin": "Generic_PIN",
    "generic_sms": "Generic_SMS",
    "generic_mix": "Generic_MIX",
    "player_mix": "Player_MIX",
    "stream_mix": "Stream_MIX",
    "videotube_mix": "VideoTube_MIX"
}

helper.thumbsItem = [
      {
        thumb: require('@/assets/images/playtheque-thumbs/thumb-01.jpg'),
      },
      {
        thumb: require('@/assets/images/playtheque-thumbs/thumb-02.jpg'),
      },
       {
        thumb: require('@/assets/images/playtheque-thumbs/thumb-03.jpg'),
      },
      {
        thumb: require('@/assets/images/playtheque-thumbs/thumb-04.jpg'),
      },
      {
        thumb: require('@/assets/images/playtheque-thumbs/thumb-05.jpg'),
      },
      {
        thumb: require('@/assets/images/playtheque-thumbs/thumb-06.jpg'),
      },
]

helper.languagePhoneFormat = language_phones_format;
helper.portal_name = portal_name;
helper.product_name = product_name;
helper.product_dns = product_dns;
helper.msisdnPhoneFormat = msisdn_phones_format;
helper.landingNamesArray = landing_name_array;

export const helpers = helper;