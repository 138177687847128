import Vue from 'vue'
import UUID from 'vue-uuid';
import VueCookies from 'vue-cookies';
import device from "vue-device-detector";
import VueLazyload from 'vue-lazyload'


import App from './App.vue';
import router from './router';
import titleMixin from './mixins/titleMixin';
import { i18n } from './plugins/i18n.js';

import './assets/css/global.css';
import './assets/css/fonts.css';

Vue.config.productionTip = false;

Vue.mixin(titleMixin);
Vue.use(UUID);
Vue.use(VueCookies);
Vue.use(device);
Vue.use(VueLazyload);

new Vue({
    i18n,
    router,
    render: h => h(App),
}).$mount('#app')