import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from "axios";
import messages from '@/locales/PT/en.json';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'uk', // locale: set the default language
    fallbackLocale: 'en',
    messages,
    silentTranslationWarn: true
});

const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export async function loadLanguageAsync(langWithLandingCode) {

    const lang = langWithLandingCode.split('_')[0];
    const landingCode = langWithLandingCode.split('_')[1];

    // If the same language
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang))
    }
    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }
    // If the language hasn't been loaded yet
    let messages = null;
    messages = await import(/* webpackChunkName: "i18n-locale" */ `@/locales/${landingCode}/${lang}.json`)
    if (messages != null) {
        i18n.setLocaleMessage(lang, messages.default)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
    }
}