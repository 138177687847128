import Vue from 'vue';
import VueRouter from 'vue-router';
import { i18n } from '../plugins/i18n.js';
import { helpers } from '../helper/portal_config';
import { loadLanguageAsync } from '@/plugins/i18n.js';

Vue.config.silent = true;

//pin flow
const NotFound = () => import('@/views/NotFound.vue');
const LegalsTerms = () => import('@/views/legals/terms.vue');
const LegalsPrivacy = () => import('@/views/legals/privacy.vue');
const LegalsCookiesPolicy = () => import('@/views/legals/cookies_policy.vue');
const LegalsContact = () => import('@/views/legals/contact.vue');
const DynamicIndex = () => import('@/views/landing-dynamic/pin/index.vue');
const DynamicPin = () => import('@/views/landing-dynamic/pin/pin.vue');
const DynamicSuccess = () => import('@/views/landing-dynamic/pin/success.vue');
const DownloadIndex = () => import('@/views/landing-download/pin/index.vue');
const DownloadPin = () => import('@/views/landing-download/pin/pin.vue');
const DownloadSuccess = () => import('@/views/landing-download/pin/success.vue');
const GenericIndex = () => import('@/views/landing-generic/pin/index.vue');
const GenericPIN = () => import('@/views/landing-generic/pin/pin.vue');
const GenericSuccess = () => import('@/views/landing-generic/pin/success.vue');
const SecureIndex = () => import('@/views/landing-secure/pin/index.vue');
const SecurePIN = () => import('@/views/landing-secure/pin/pin.vue');
const SecureSuccess = () => import('@/views/landing-secure/pin/success.vue');
const GameIndex = () => import('@/views/landing-game/pin/index.vue');
const GamePIN = () => import('@/views/landing-game/pin/pin.vue');
const GameSuccess = () => import('@/views/landing-game/pin/success.vue');
const PlayerIndex = () => import('@/views/landing-player/pin/index.vue');
const PlayerPin = () => import('@/views/landing-player/pin/pin.vue');
const PlayerSuccess = () => import('@/views/landing-player/pin/success.vue');
const StreamIndex = () => import('@/views/landing-streaming/pin/index.vue');
const StreamPin = () => import('@/views/landing-streaming/pin/pin.vue');
const StreamSuccess = () => import('@/views/landing-streaming/pin/success.vue');
const VideoTubeIndex = () => import('@/views/landing-videotube/pin/index.vue');
const VideoTubePin = () => import('@/views/landing-videotube/pin/pin.vue');
const VideoTubeSuccess = () => import('@/views/landing-videotube/pin/success.vue');

const smsDynamicIndex = () => import('@/views/landing-dynamic/sms/index.vue');
const smsDownloadIndex = () => import('@/views/landing-download/sms/index.vue');
const smsGenericIndex = () => import('@/views/landing-generic/sms/index.vue');
const smsSecureIndex = () => import('@/views/landing-secure/sms/index.vue');
const smsGameIndex = () => import('@/views/landing-game/sms/index.vue');
const smsPlayerIndex = () => import('@/views/landing-player/sms/index.vue');
const smsStreamIndex = () => import('@/views/landing-streaming/sms/index.vue');
const smsVideotubeIndex = () => import('@/views/landing-videotube/sms/index.vue');

// mixed flow with click2sms/mo and pin flow
const mixDynamicIndex = () => import('@/views/landing-dynamic/mixed/index.vue');
const mixDynamicSMS = () => import('@/views/landing-dynamic/mixed/sms.vue');
const mixDynamicPIN = () => import('@/views/landing-dynamic/mixed/pin.vue');

const mixDownloadIndex = () => import('@/views/landing-download/mixed/index.vue');
const mixDownloadSMS = () => import('@/views/landing-download/mixed/sms.vue');
const mixDownloadPIN = () => import('@/views/landing-download/mixed/pin.vue');

const mixGenericIndex = () => import('@/views/landing-generic/mixed/index.vue');
const mixGenericSMS = () => import('@/views/landing-generic/mixed/sms.vue');
const mixGenericPIN = () => import('@/views/landing-generic/mixed/pin.vue');

const mixSecureIndex = () => import('@/views/landing-secure/mixed/index.vue');
const mixSecureSMS = () => import('@/views/landing-secure/mixed/sms.vue');
const mixSecurePIN = () => import('@/views/landing-secure/mixed/pin.vue');

const mixGameIndex = () => import('@/views/landing-game/mixed/index.vue');
const mixGameSMS = () => import('@/views/landing-game/mixed/sms.vue');
const mixGamePIN = () => import('@/views/landing-game/mixed/pin.vue');

const mixPlayerIndex = () => import('@/views/landing-player/mixed/index.vue');
const mixPlayerSMS = () => import('@/views/landing-player/mixed/sms.vue');
const mixPlayerPIN = () => import('@/views/landing-player/mixed/pin.vue');

const mixStreamIndex = () => import('@/views/landing-streaming/mixed/index.vue');
const mixStreamSMS = () => import('@/views/landing-streaming/mixed/sms.vue');
const mixStreamPIN = () => import('@/views/landing-streaming/mixed/pin.vue');

const mixVideotubeIndex = () => import('@/views/landing-videotube/mixed/index.vue');
const mixVideotubeSMS = () => import('@/views/landing-videotube/mixed/sms.vue');
const mixVideotubePIN = () => import('@/views/landing-videotube/mixed/pin.vue');

const operatorIpGenerator = () => import('@/views/operator-ip-generator/index.vue');

Vue.use(VueRouter);

const routes = [{
        path: '*',
        component: DownloadIndex,
    },
    // ALL flows route
    {
        path: '/error',
        component: NotFound,
    },
    {
        path: '/terms',
        component: LegalsTerms,
    },
    {
        path: '/privacy',
        component: LegalsPrivacy,
    },
    {
        path: '/cookies-policy',
        component: LegalsCookiesPolicy,
    },
    {
        path: '/contact',
        component: LegalsContact,
    },
    {
        path: '/operator_ip_generator/:opecode',
        component: operatorIpGenerator,
    },
    // DYNAMIC LANDINGS
    {
        path: helpers.landing_urls.dynamic_mix_pin + 'pin/:dynamic_type?/:dynamic_subtype?/:version?/:landing_session_id?/:error_code?',
        component: mixDynamicPIN,
        name: "mixDynamicPIN"
    },
    {
        path: helpers.landing_urls.dynamic_pin + 'pin/:dynamic_type?/:dynamic_subtype?/:version?/:landing_session_id?/:error_code?',
        component: DynamicPin,
        name: "DynamicPin"
    },
    {
        path: helpers.landing_urls.dynamic_pin + 'success/:dynamic_type?/:new_access_code?',
        component: DynamicSuccess,
        name: "DynamicSuccess"
    },
    {
        path: helpers.landing_urls.dynamic_pin+ ':dynamic_type?/:dynamic_subtype?/:version?/',
        component: DynamicIndex,
    },
    {
        path: helpers.landing_urls.dynamic_sms + ':type?/:dynamic_type?/:dynamic_subtype?/:version?/',
        component: smsDynamicIndex,
        name: "smsDynamicIndex"
    },
    {
        path: helpers.landing_urls.dynamic_mix + ':dynamic_type?/:dynamic_subtype?/:version?/',
        component: mixDynamicIndex,
        name: "mixDynamicIndex"
    },
    {
        path: helpers.landing_urls.dynamic_mix_sms + ':type?/:dynamic_type?/:dynamic_subtype?/:version?/',
        component: mixDynamicSMS,
        name: "mixDynamicSMS"
    },

    // PINS FLOW URLs
    {
        path: helpers.landing_urls.secure_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: SecurePIN,
        name: "SecurePIN",
    },
    {
        path: helpers.landing_urls.secure_pin + 'success/:new_access_code?',
        component: SecureSuccess,
        name: "SecureSuccess",
    },
    {
        path: helpers.landing_urls.secure_pin + ':version?',
        component: SecureIndex,
        //alias: helpers.landing_urls.secure_pin
    },
    {
        path: helpers.landing_urls.generic_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: GenericPIN,
        name: "GenericPIN",
    },
    {
        path: helpers.landing_urls.generic_pin + 'success/:new_access_code?',
        component: GenericSuccess,
        name: "GenericSuccess",
    },
    {
        path: helpers.landing_urls.generic_pin + ':version?',
        component: GenericIndex,
        //alias: helpers.landing_urls.generic_pin
    },
    {
        path: helpers.landing_urls.download_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: DownloadPin,
        name: "DownloadPin"
    },
    {
        path: helpers.landing_urls.download_pin + 'success/:new_access_code?',
        component: DownloadSuccess,
        name: "DownloadSuccess"
    },
    {
        path: helpers.landing_urls.download_pin + ':version?',
        component: DownloadIndex,
        //alias: helpers.landing_urls.download_pin
    },
    {
        path: helpers.landing_urls.game_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: GamePIN,
        name: "GamePIN"
    },
    {
        path: helpers.landing_urls.game_pin + 'success/:new_access_code?',
        component: GameSuccess,
        name: "GameSuccess"
    },
    {
        path: helpers.landing_urls.game_pin + ':version?',
        component: GameIndex,
    },
    {
        path: helpers.landing_urls.player_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: PlayerPin,
        name: "PlayerPin"
    },
    {
        path: helpers.landing_urls.player_pin + 'success/:new_access_code?',
        component: PlayerSuccess,
        name: "PlayerSuccess"
    },
    {
        path: helpers.landing_urls.player_pin + ':version?',
        component: PlayerIndex,
    },
    {
        path: helpers.landing_urls.stream_pin + ':version?',
        component: StreamIndex,
    },
    {
        path: helpers.landing_urls.stream_pin + 'success/:new_access_code?',
        component: StreamSuccess,
        name: "StreamSuccess"
    },
    {
        path: helpers.landing_urls.stream_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: StreamPin,
        name: "StreamPin"
    },
    {
        path: helpers.landing_urls.videotube_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: VideoTubePin,
        name: "VideotubePin"
    },
    {
        path: helpers.landing_urls.videotube_pin + 'success/:new_access_code?',
        component: VideoTubeSuccess,
        name: "VideotubeSuccess"
    },
    {
        path: helpers.landing_urls.videotube_pin + ':version?',
        component: VideoTubeIndex,
    },
    
    // ClickToSms/MO FLOW URLs
    {
        path: helpers.landing_urls.secure_sms + ':type?/:version?/',
        component: smsSecureIndex,
        name: "smsSecureIndex"
    },
    {
        path: helpers.landing_urls.generic_sms + ':type?/:version?/',
        component: smsGenericIndex,
        name: "smsGenericIndex"
    },
    {
        path: helpers.landing_urls.download_sms + ':type?/:version?/',
        component: smsDownloadIndex,
        name: "smsDownloadIndex"
    },
    {
        path: helpers.landing_urls.game_sms + ':type?/:version?/',
        component: smsGameIndex,
        name: "smsGameIndex"
    },
    {
        path: helpers.landing_urls.player_sms + ':type?/:btntype?/:version?/',
        component: smsPlayerIndex,
        name: "smsPlayerIndex"
    },
    {
        path: helpers.landing_urls.stream_sms + ':type?/:btntype?/:version?/',
        component: smsStreamIndex,
        name: "smsStreamIndex"
    },
    {
        path: helpers.landing_urls.videotube_sms + ':type?/:btntype?/:version?/',
        component: smsVideotubeIndex,
        name: "smsVideotubeIndex"
    },
    
    // MIXED FLOW URLs
    {
        path: helpers.landing_urls.secure_mix + ':version?',
        component: mixSecureIndex,
        name: "mixSecureIndex"
    },
    {
        path: helpers.landing_urls.generic_mix + ':version?',
        component: mixGenericIndex,
        name: "mixGenericIndex"
    },
    {
        path: helpers.landing_urls.download_mix + ':version?',
        component: mixDownloadIndex,
        name: "mixDownloadIndex"
    },
    {
        path: helpers.landing_urls.game_mix + ':version?',
        component: mixGameIndex,
        name: "mixGameIndex"
    },
    {
        path: helpers.landing_urls.player_mix + ':version?',
        component: mixPlayerIndex,
        name: "mixPlayerIndex"
    },
    {
        path: helpers.landing_urls.stream_mix + ':version?',
        component: mixStreamIndex,
        name: "mixStreamIndex"
    },
    {
        path: helpers.landing_urls.videotube_mix + ':version?',
        component: mixVideotubeIndex,
        name: "mixVideotubeIndex"
    },
    // mixed pin and sms flows
    {
        path: helpers.landing_urls.secure_mix_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: mixSecurePIN,
        name: "mixSecurePIN"
    },
    {
        path: helpers.landing_urls.secure_mix_sms + ':type?/:version?',
        component: mixSecureSMS,
        name: "mixSecureSMS"
    },
    {
        path: helpers.landing_urls.generic_mix_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: mixGenericPIN,
        name: "mixGenericPIN"
    },
    {
        path: helpers.landing_urls.generic_mix_sms + ':type?/:version?',
        component: mixGenericSMS,
        name: "mixGenericSMS"
    },
    {
        path: helpers.landing_urls.download_mix_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: mixDownloadPIN,
        name: "mixDownloadPIN"
    },
    {
        path: helpers.landing_urls.download_mix_sms + ':type?/:version?',
        component: mixDownloadSMS,
        name: "mixDownloadSMS"
    },
    {
        path: helpers.landing_urls.game_mix_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: mixGamePIN,
        name: "mixGamePIN"
    },
    {
        path: helpers.landing_urls.game_mix_sms + ':type?/:version?',
        component: mixGameSMS,
        name: "mixGameSMS"
    },
    {
        path: helpers.landing_urls.player_mix_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: mixPlayerPIN,
        name: "mixPlayerPIN"
    },
    {
        path: helpers.landing_urls.player_mix_sms + ':type?/:version?',
        component: mixPlayerSMS,
        name: "mixPlayerSMS"
    },
    {
        path: helpers.landing_urls.stream_mix_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: mixStreamPIN,
        name: "mixStreamPIN"
    },
    {
        path: helpers.landing_urls.stream_mix_sms + ':type?/:version?',
        component: mixStreamSMS,
        name: "mixStreamSMS"
    },
    {
        path: helpers.landing_urls.videotube_mix_pin + 'pin/:version?/:landing_session_id?/:error_code?',
        component: mixVideotubePIN,
        name: "mixVideotubePIN"
    },
    {
        path: helpers.landing_urls.videotube_mix_sms + ':type?/:version?',
        component: mixVideotubeSMS,
        name: "mixVideotubeSMS"
    },

    // MIX success
    {
        path: helpers.landing_urls.dynamic_mix + 'success/:dynamic_type?/:dynamic_subtype?/:new_access_code?',
        component: DynamicSuccess,
        name: "mixDynamicSuccess"
    },
    {
        path: helpers.landing_urls.download_mix + 'success/:new_access_code?',
        component: DownloadSuccess,
        name: "mixDownloadSuccess"
    },
    {
        path: helpers.landing_urls.game_mix + 'success/:new_access_code?',
        component: GameSuccess,
        name: "mixGameSuccess"
    },
    {
        path: helpers.landing_urls.secure_mix + 'success/:new_access_code?',
        component: SecureSuccess,
        name: "mixSecureSuccess"
    },
    {
        path: helpers.landing_urls.generic_mix + 'success/:new_access_code?',
        component: GenericSuccess,
        name: "mixGenericSuccess"
    },
    {
        path: helpers.landing_urls.player_mix + 'success/:new_access_code?',
        component: PlayerSuccess,
        name: "mixPlayerSuccess"
    },
    {
        path: helpers.landing_urls.stream_mix + 'success/:new_access_code?',
        component: StreamSuccess,
        name: "mixStreamSuccess"
    },
    {
        path: helpers.landing_urls.videotube_mix + 'success/:new_access_code?',
        component: VideoTubeSuccess,
        name: "mixVideotubeSuccess"
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ x: 0, y: 0 });
            }, 400);
        });
    },
});

router.beforeEach((to, from, next) => {
    const { host } = window.location;
    const parts = host.split('.');
    const lang = parts[0];

    var ptl = helpers.portal_name
    if (to.query.hasOwnProperty("ptl")){
        ptl = to.query.ptl
    }
    let langWithLandingCode;
    console.log("ptl="+ptl);
    if (lang.substring(0,9) == 'localhost') {
        langWithLandingCode = 'cz'+'_'+helpers.landing_code[ptl];
        //console.log(langWithLandingCode)
    } else if (parts[1] === 'com') {
        langWithLandingCode = 'en'+'_'+helpers.landing_code[ptl];
    } else if (lang.substring(0,3) == 'srb') {
        langWithLandingCode = lang.substring(0,3)+'_'+helpers.landing_code[ptl];
    } else if (lang.substring(0,5) == 'rougo') {
        langWithLandingCode = lang.substring(0,3)+'_'+helpers.landing_code[ptl];
    } else if (lang.substring(0,3) == 'lva') {
        langWithLandingCode = lang.substring(0,3)+'_'+helpers.landing_code[ptl];
    } else if (lang.substring(0,3) == 'ltu') {
        langWithLandingCode = lang.substring(0,3)+'_'+helpers.landing_code[ptl];
    } else if (lang.substring(0,2) == 'uk') {
        langWithLandingCode = 'en_'+helpers.landing_code[ptl];
    } else if (lang && lang.length >= 2) {
        langWithLandingCode = lang.substring(0,2)+'_'+helpers.landing_code[ptl];
    }
    //console.log('language = '+langWithLandingCode);
    i18n.fallbackLocale = 'en'+'_'+helpers.landing_code[ptl];
    loadLanguageAsync(langWithLandingCode).then(() => next())
});

export default router;